import React from 'react'
import PropTypes from 'prop-types'
import { H4 } from '@renderbus/common/components'

import styled from 'styled-components'
import { spacing, typography, color } from '@renderbus/common/theme'

import hotlineIcon from '../../../images/about/24hour.png'
import phoneIcon from '../../../images/about/phone.png'
import emailIcon from '../../../images/about/email.png'
import faxIcon from '../../../images/about/fax.png'
import addressIcon from '../../../images/about/address.png'

const ContactInfoTitle = styled(H4)`
  margin: 0 ${spacing.large};
  font-weight: 500;
  font-size: ${typography.text};
  margin: ${spacing.large} 0;
`
const ContactInfoItem = styled.p`
  line-height: ${typography.h2};
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  margin: 0;
  margin-left: ${p => p.isHiddenImg && spacing.large};
  img {
    margin-right: 13px;
  }
`

class ContactInfo extends React.PureComponent {
  render() {
    let {
      title,
      titleSMDown,
      hotline,
      phone,
      email,
      fax,
      address,
      renderLine,
      privateLine,
    } = this.props
    return (
      <div>
        {title && <ContactInfoTitle>{title}</ContactInfoTitle>}
        {titleSMDown && <ContactInfoTitle>{titleSMDown}</ContactInfoTitle>}
        {hotline && (
          <ContactInfoItem>
            <img src={hotlineIcon} alt='hotline' />
            <span>24小时热线:{hotline}</span>
          </ContactInfoItem>
        )}
        {renderLine && (
          <ContactInfoItem>
            <img src={phoneIcon} alt='phone' />
            <span>渲染专线:{renderLine}</span>
          </ContactInfoItem>
        )}
        {privateLine && (
          <ContactInfoItem isHiddenImg>
            <span>私有化业务咨询:{privateLine}</span>
          </ContactInfoItem>
        )}
        {phone && (
          <ContactInfoItem>
            <img src={phoneIcon} alt='phone' />
            <span>电话:{phone}</span>
          </ContactInfoItem>
        )}
        {email && (
          <ContactInfoItem>
            <img src={emailIcon} alt='email' />
            <span>电邮:{email}</span>
          </ContactInfoItem>
        )}
        {fax && (
          <ContactInfoItem>
            <img src={faxIcon} alt='fax' />
            <span>传真:{fax}</span>
          </ContactInfoItem>
        )}
        {address && (
          <ContactInfoItem>
            <img src={addressIcon} alt='address' />
            <span>地址:{address}</span>
          </ContactInfoItem>
        )}
      </div>
    )
  }
}

ContactInfo.propTypes = {
  title: PropTypes.string,
  titleSMDown: PropTypes.string,
  hotline: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  fax: PropTypes.string,
  address: PropTypes.string,
}

export default ContactInfo
