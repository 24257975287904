import styled, { css } from 'styled-components'
import { typography, spacing, color, hideSMDown } from '@renderbus/common/theme'
import { H1, H3, H2, Flex, Anchor, Wrapper } from '@renderbus/common/components'
import Media from '@renderbus/common/theme/media'
import GatsbyImage from 'gatsby-image'

export const BannerContainer = styled.div`
  position: relative;
  padding-top: 30vh;
  height: 100vh;
  text-align: center;
  color: white;

  ${Media.lessThan(Media.small)} {
    height: 100vw;
    padding-top: 20vh;
  }
`

export const BannerTitle = styled(H1)`
  margin-top: ${spacing.small};

  ${Media.lessThan(Media.small)} {
    font-size: 6.4vmin;
  }
`

export const BannerSubTitle = styled(H3)`
  margin: ${spacing.small} 0;
  font-weight: 400;

  ${Media.lessThan(Media.small)} {
    font-size: 3.2vmin;
  }
`

export const PlayImg = styled.img`
  ${hideSMDown};
`

export const WarpperContainer = styled.div`
  padding: 100px 0 80px;
  background: ${p => p.lighter && color.panel};

  ${({ type }) => {
    if (type === 'summary')
      return css`
        padding: 85px 0 100px;
      `
  }}

  ${Media.lessThan(Media.small)} {
    padding: 50px 0;
  }
`
export const WrapperTitle = styled(H2)`
  font-size: 40px;
  text-align: center;
  line-height: 1;

  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
  }
`
export const IntroContent = styled.div`
  margin-top: 48px;

  p {
    margin: ${spacing.large} 0;
    color: ${color.prompt};
    font-size: ${typography.textSmall};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const FamilyContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  & > .family-button {
    position: absolute;
    top: 45.5%;
    left: 50%;
    transform: translate(-50%);
  }

  ${Media.lessThan(Media.small)} {
    background: #333333;
    padding: 8vmin 0 16vmin;

    & > .family-button {
      top: 22%;
    }
  }
`

export const FamilyInfo = styled.div`
  position: absolute;
  top: 60.75%;
  left: 50%;
  transform: translate(-50%);
  width: 1200px;
  height: 220px;
  margin: 0 auto;
  padding: 54px 62px;
  color: white;
  background: #0003;
  font-size: ${typography.text};
  text-align: left;

  h2 {
    display: none;
  }

  p {
    margin: 0 0 ${spacing.base} 0;

    &:last-child {
      margin: 0;
    }
  }

  ${Media.lessThan(Media.small)} {
    position: relative;
    top: initial;
    left: initial;
    transform: translate(0);
    padding: 0;
    width: 92vmin;
    font-size: ${typography.textThin};
    background: transparent;
    height: auto;

    h2 {
      display: block;
      font-size: ${typography.h3};
      margin: 6.666vmin 0;
    }
  }
`

export const PartnerWrapper = styled.div`
  background: #333333;
  padding: 100px 0;

  ${Wrapper} {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  ${Media.lessThan(Media.small)} {
    padding: 9.3333vmin 0;

    ${WrapperTitle} {
      margin-bottom: 8vmin;
    }
  }
`

export const PartnerContent = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;

  ${Media.lessThan(Media.small)} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
    height: 29.73333vmin;
    margin: 0;
  }
`

export const ContactContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 52px 0 80px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
  }
`
export const ContactContent = styled.div`
  margin-left: 70px;

  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-left: 0;
  }
`
export const ContactTitle = styled(H3)`
  font-weight: 500;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin: ${spacing.large} 0;
  }
`
export const BrandWrapper = styled(Flex)`
  justify-content: space-between;
  padding: 0 100px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    padding: 0;
  }
`
export const Brand = styled(Flex)`
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.base};
    img {
      padding-bottom: ${spacing.base};
    }
  }
`
export const BrandContent = styled.div`
  color: white;
  padding-left: ${spacing.large};
  border-left: 1px solid white;
  margin-left: ${spacing.large};
  p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.base};
  }
`
export const BrandTitle = styled(H1)`
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  ${Media.lessThan(Media.small)} {
    font-size: 1.875rem;
  }
`

export const TeamImage = styled(GatsbyImage)`
  & > div {
    /* TODO: 升级版本到 3.x 以上后，此问题会解决
     * GatsbyImage 2.x 版本有个问题，如果 fluid 传入一个数组，
     * 则会直接取出数组第一项的 宽高比来做计算，而不判断 media。
     * 故这里需要自己计算正确的值（100 / 2.4）
     */
    padding-bottom: 41.6667% !important;
  }

  ${Media.lessThan(Media.small)} {
    width: 92vmin;
    height: auto;
    margin: 0 auto;

    & > div {
      /* （100 / 1.8449） */
      padding-bottom: 54.2029% !important;
    }
  }
`

export const PartnerImageWrapper = styled.div`
  display: flex;
  width: 220px;
  height: 104px;
  background: #fff;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & + & {
    margin: 0 0 0 16px;
  }

  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 13.867vmin;

    & + & {
      margin: 0;
    }

    & > img {
      max-width: 90%;
    }
  }
`

export const More = styled(Anchor)`
  position: relative;
  display: inline-block;
  margin: 60px 0 0;
  color: ${color.primary};

  &::after {
    position: absolute;
    content: '>';
    display: block;
    right: -10px;
    top: 0;
  }

  ${Media.lessThan(Media.small)} {
    margin: 3.73vmin 0 0;
  }
`
