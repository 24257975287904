import React from 'react'
import { graphql } from 'gatsby'
import { Billboard, Wrapper, Button, Modal, Column, Row, SEO } from '@renderbus/common/components'
import Layout from '../molecules/layout'
import BDMap from '../molecules/about/bd-map'
import ContactInfo from '../molecules/about/contact-info'
import RegisterBanner from '../molecules/register-banner'
import {
  BannerContainer,
  BannerTitle,
  BannerSubTitle,
  WarpperContainer,
  WrapperTitle,
  IntroContent,
  FamilyContainer,
  FamilyInfo,
  PartnerContent,
  ContactContainer,
  ContactContent,
  ContactTitle,
  PlayImg,
  BrandWrapper,
  Brand,
  BrandContent,
  BrandTitle,
  TeamImage,
  PartnerImageWrapper,
  More,
  PartnerWrapper,
} from './about.atom'
import partnerDetails from '../molecules/partner/partnerDetails'
import playIcon from '../images/architectural-rendering/xgt_play.png'
import playIconHover from '../images/architectural-rendering/xgt_play_hover.png'

import download from '../images/about/download.png'

class About extends React.PureComponent {
  state = {
    playIconSrc: playIcon,
    showVideo: false,
  }

  HoverPlayIcon(isHover) {
    if (isHover) {
      this.setState({ playIconSrc: playIconHover })
    } else {
      this.setState({ playIconSrc: playIcon })
    }
  }

  ShowVideo() {
    this.setState({ showVideo: true })
    if (this.videoRef) this.videoRef.play()
  }
  CloseVideo(event) {
    if (event) event.preventDefault()
    if (this.videoRef) this.videoRef.pause()
    this.setState({ showVideo: false })
  }

  setVideoRef = ref => {
    this.videoRef = ref
  }

  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    const { playIconSrc, showVideo } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='Renderbus瑞云科技简介'
          keywords='瑞云科技'
          description='Renderbus瑞云渲染农场，国内最大规模CG渲染农场平台,是Vray官方唯一推荐的商业云渲染农场,全面支持3dsMax,Maya,Vray,MentalRay等软件及插件,为CG渲染行业提供最好的云渲染解决方案'
        />
        <BannerContainer>
          <PlayImg
            style={{ cursor: 'pointer' }}
            src={playIconSrc}
            onMouseEnter={() => {
              this.HoverPlayIcon(true)
            }}
            onMouseLeave={() => {
              this.HoverPlayIcon(false)
            }}
            alt='play'
            onClick={() => {
              this.ShowVideo()
            }}
          />
          <BannerTitle>WE ARE RAYVISION FAMILY</BannerTitle>
          <BannerSubTitle>YOUR POWERFUL RENDER FARM</BannerSubTitle>
          <Billboard
            imgStyle={{ filter: 'brightness(65%)' }}
            fluid={[
              data.banner.childImageSharp.fluid,
              { media: '(max-width: 780px)', ...data.bannerMb.childImageSharp.fluid },
            ]}
          />
        </BannerContainer>
        <WarpperContainer type='summary'>
          <Wrapper>
            <WrapperTitle>中国云渲染先行者</WrapperTitle>
            <IntroContent>
              <p>
                深圳市瑞云科技股份有限公司是一家专注为视觉行业提供垂直云计算服务的公司，用户超20万，遍及50多个国家和地区，包括奥斯卡金像奖得主、知名的影视动画、视效、建筑可视化、游戏工作室，瑞云科技旗下云渲染品牌，Renderbus瑞云渲染被誉为中国云渲染的先行者，代表案例包括电影《长津湖》、《战狼2》、《哪吒之魔童降世》、《流浪地球》等。瑞云渲染作为亚洲前沿的云渲染平台，致力于提供专业可靠、安全稳定、可持续创新的云渲染解决方案，助力推动行业快速发展。
              </p>
              <p>
                自2010年推出第一个产品Renderbus以来，瑞云以基于专利技术的云渲染平台以及专业的TD服务团队，迅速获得业界一致好评。瑞云的核心成员在视觉行业从业逾20年，
                是一个来自中国、美国、新加坡、印度、台湾、香港等多地的国际化的团队，致力通过技术创新给行业带来低成本、高效、可靠的云服务。同时，瑞云还拥有一支在并行计算、大规模集群管理与调度、高性能
                I/O系统等专业方向经验丰富的系统开发团队。瑞云渲染平台拥有单集群超10000节点的大规模算力池，可以为不同规格的项目提供高弹性、可定制化的云渲染服务。
              </p>
              <p>
                除了云渲染平台Renderbus以及海外版Fox
                Renderfarm，随着5G的普及，瑞云科技作为“新型基础设施”，专门针对视觉行业提供了一系列IaaS、PaaS及SaaS云服务，包括青椒云云端图形工作站、3DCAT实时渲染云平台、Raysync镭速高性能传输加速技术，满足图形图像行业用户的专业需求，并支持用户在云端实现全球协同工作，使用户摆脱沉重的IT和计算设施投入，将更多的精力和资源放在内容创作和运营上，大大提高行业生产协作效率，并极大降低成本，从而推动整个行业快速发展。
              </p>
              <p>瑞云的投资方包括丝路视觉，阿里巴巴，君联资本，光远资本，中信证券等。</p>
            </IntroContent>
          </Wrapper>
        </WarpperContainer>
        <FamilyContainer>
          <TeamImage
            imgStyle={{ objectFit: 'contain' }}
            fluid={[
              data.teamPhoto.childImageSharp.fluid,
              { ...data.teamPhotoMb.childImageSharp.fluid, media: '(max-width: 812px)' },
            ]}
          />
          <Button
            as='a'
            href='https://rayvision.zhiye.com/'
            target='_blank'
            rel='nofollow'
            className='family-button'
          >
            加入瑞云
          </Button>
          <FamilyInfo>
            <h2>瑞云伐木累</h2>
            <p>
              这里有自由的发挥空间，舒适的工作环境，有爱的团队氛围，超越同行的福利......这里充满了挑战和激情，业内精英与牛人大咖汇聚，头脑风暴与思维火花碰撞，每个人的优势和能力都得以充分发挥。我们全力以赴，我们力臻完美！
            </p>
            <p>
              在这里，我们不仅是工作伙伴，更是家人。我们会定期举办各种户内外活动。每周三下午的茶话会，周四的篮球比赛，游泳俱乐部，户外烧烤，还有各种旅游，等你来加入！
            </p>
          </FamilyInfo>
        </FamilyContainer>
        <WarpperContainer>
          <Wrapper>
            <BrandWrapper>
              <Brand>
                <img src={download} alt='download' />
                <BrandContent>
                  <BrandTitle>Renderbus品牌标识</BrandTitle>
                  <p>下载我们的官方徽标和使用指南</p>
                </BrandContent>
              </Brand>
              <Button as='a' href='/renderbus-brand.zip' target='_blank' rel='nofollow'>
                下载
              </Button>
            </BrandWrapper>
          </Wrapper>
        </WarpperContainer>
        <PartnerWrapper>
          <Wrapper>
            <WrapperTitle>我们的合作伙伴</WrapperTitle>
            <PartnerContent>
              {partnerDetails.slice(0, 6).map(({ src, title }) => {
                return (
                  <PartnerImageWrapper key={title}>
                    <img alt={title} src={src} />
                  </PartnerImageWrapper>
                )
              })}
            </PartnerContent>
            <More href='/partner.html' rel='nofollow'>
              查看更多
            </More>
          </Wrapper>
        </PartnerWrapper>
        <WarpperContainer>
          <Wrapper>
            <WrapperTitle id='contact_us'>联系我们</WrapperTitle>
            <ContactContainer>
              <BDMap width='570px' />
              <ContactContent>
                <ContactTitle>深圳市瑞云科技股份有限公司</ContactTitle>
                <ContactInfo
                  titleSMDown='深圳-总部'
                  hotline='400-0034560'
                  renderLine='18612698101'
                  privateLine='18612698101'
                  email='service@rayvision.com'
                  address='深圳市南山区商华路2号阳光科创中心B座17层'
                ></ContactInfo>
              </ContactContent>
            </ContactContainer>
            <Row style={{ justifyContent: 'space-between' }}>
              <Column xs='12' lg='2.5'>
                <ContactInfo
                  title='北京分公司'
                  phone='18948780640'
                  fax='010-63922824'
                  address='北京市朝阳区大成国际3号楼B座1801'
                ></ContactInfo>
              </Column>
              <Column xs='12' lg='2.5'>
                <ContactInfo
                  title='上海分公司'
                  phone='13402051543'
                  address='上海市嘉定区南翔镇金迈路515弄冰风科技园1号楼6层605室'
                ></ContactInfo>
              </Column>
              <Column xs='12' lg='2.5'>
                <ContactInfo
                  title='成都分公司'
                  phone='18681501854'
                  address='成都市高新区盛和一路88号康普雷斯B座1605'
                ></ContactInfo>
              </Column>
              <Column xs='12' lg='2.5'>
                <ContactInfo
                  title='深圳公司'
                  phone='18675529822'
                  address='深圳市南山区商华路2号阳光科创中心B座5层'
                ></ContactInfo>
              </Column>
            </Row>
          </Wrapper>
        </WarpperContainer>
        <RegisterBanner isNoLimit />
        <Modal
          visible={showVideo}
          onClose={e => {
            this.CloseVideo(e)
          }}
          darkTheme={true}
        >
          <video
            src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/Team-Renderbus-2021.mp4'
            rel='nofollow'
            allowFullScreen={true}
            controls={true}
            autoPlay={true}
            title='client-maya'
            style={{ width: '800px' }}
            ref={this.setVideoRef}
          />
        </Modal>
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "about/bg.png" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { eq: "about/about-banner-mb.webp" }) {
      ...fluidImage
    }
    teamPhoto: file(relativePath: { eq: "about/about-college-2021.png" }) {
      ...fluidImage
    }
    teamPhotoMb: file(relativePath: { eq: "about/about-college-2021-mb.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
